.faq-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 40px 0;
    width: 100%;
}

.billboard {
    background-color: #FCF8F5;
    border-width: 10px;
    padding: 60px 40px;
    position: relative;
    width: 70%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 600px;
}

.billboard::after,
.billboard::before {
    content: '';
    position: absolute;
    bottom: -40px;
    width: 40px;
    height: 40px;
    background-color: #FCF8F5;
}

.billboard::after {
    left: 25%;
}

.billboard::before {
    right: 25%;
}

.sub_textspeaker {
    font-family: "Verdana", monospace;
    font-weight: 300;
    font-size: 15px;
    color: #FCF8F5;
}

.body_textspeaker {
    margin: 0;
    font-family: "Helvetica", monospace;
    font-weight: 400;
    font-size: 30px;
    color: #FFFFFF;
}

.sub_textspeakercontent {
    font-family: "Verdana", monospace;
    font-weight: 100;
    font-size: 15px;
    color: #FCF8F5;
}

/*8==============================================D*/
/*8==============================================D*/
/*8==============================================D*/


.faq_header_text {
    font-family: "Formula1", sans-serif;
    font-size: 54px;
    color: #343434;
}

.faq-panes {
    display: flex;
    justify-content: space-between;
    gap: 20px;
    flex-grow: 1;
}

.faq-image-placeholder {
    width: calc(100% - 40px);
    padding-bottom: calc(100% - 40px);
    background-color: #FCF8F5;
    border-radius: 20px;
    margin: 0 auto 20px;
    position: relative;
}

.faq-pane {
    background-color: #ff0000;
    border-radius: 10px;
    padding: 20px;
    width: calc(25% - 15px);
    display: flex;
    flex-direction: column;
}

.faq-heading {
    border-radius: 20px;
    background-color: #9beceb;
}

.faq-category {
    font-size: 40px;
    color: rgba(35, 35, 35, .8);
    font-family: "Helvetica", monospace;
    font-weight: 400;
}

.faq-registration {
    background-color: rgba(255, 216, 224, .8);
    border-radius: 16px;
}

.faq-registration-icon {
    border-radius: 5px;
    padding: 5px;
}

.faq-projects {
    background-color: rgba(193, 229, 255, .8);
    border-radius: 16px;
}

.faq-projects-icon {
    border-radius: 5px;
    padding: 5px;
}

.faq-info {
    background-color: rgba(212, 250, 208, .8);
    border-radius: 16px;
}

.faq-info-icon {
    border-radius: 5px;
    padding: 5px;
}

.faq-other {
    background-color: rgba(255, 247, 209, .8);
    border-radius: 16px;
}

.faq-other-icon {
    border-radius: 5px;
    padding: 5px;
}

.faq-question {
    background: transparent;
    border: none;
}

.faq-question[data-active] {
    background: transparent;
}

.faq-control {
    background-color: rgba(255,255,255, 1);
    border-radius: 10px;
    font-family: Helvetica;
    color: #114660;
    font-size: 20px;
    font-weight: 700;
}

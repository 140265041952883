@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Courier+Prime:ital,wght@0,400;0,700;1,400;1,700&display=swap');

@font-face {
    font-family: "Formula1";
    src: local("Formula1"),
        url(./assets/fonts/Formula1-Regular.otf) format("opentype");
}

@font-face {
    font-family: "Formula1";
    font-weight: 900;
    src: local("Formula1"),
        url(./assets/fonts/Formula1-Bold.otf) format("opentype");
}

@font-face {
    font-family: "Formula1-Wide";
    src: local("Formula1-Wide"),
        url(./assets/fonts/Formula1-Wide.otf) format("opentype");
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Formula1", Helvetica;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
        monospace;
}

.centered-text {
    justify-content: center;
}

.flag-button {
    position: absolute;
    text-align: center;
}

.main-road {
    background-image: url("./assets/main_road.svg");
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: 100% auto;
    background-position: center;
}

.header_text {
    font-family: "Formula1";
    margin-top: 10px;
    font-size: 55px;
}

.body_text {
    margin: 0;
    font-family: "Helvetica", monospace;
    font-size: 28px;
    color: #555555;
}

.hero_text {
    font-weight: 300;
    margin: -6px;
    font-style: normal;
}

.social_icons {
    font-size: 55px;
    color: #555555;
    transition: color 0.3s, transform .3s;
}

.social_icons:hover {
    color: #fc3f3f;
    transform: scale(1.1)
}

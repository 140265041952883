.sponsor_container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.sponsor_logo::after {
    content: "";
    position: absolute;
    width: 0;
    height: 4px;
    background-color: #FCE147;
    bottom: -20px;
    left: 5%;
    transition: width 0.4s ease;
}

.sponsor_logo {
    margin: 20px 0px 30px 0px;
    transition: transform 0.3s ease;
    transform-origin: center;
}

.sponsor_logo:hover {
    transform: scale(1.04);
}

.sponsor_logo:hover::after {
    width: 90%;
}

.stars_container {
    display: flex;
    align-items: center;
    justify-content: center;
}

.spons_star {
    position: absolute;
    margin-top: 35px;
    width: 35px;
    height: 35px;
    background-image: url('../assets/sponsors/star.svg');
    background-size: cover;
    background-position: center;
    animation: twinkle ease-in infinite alternate;
}

@keyframes twinkle {
    from {
        opacity: 1;
    }

    to {
        opacity: .2;
    }
}

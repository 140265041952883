:root {
    --events-bg-color: #ccecda;
    --events-primary-color: #059340;
    --events-text-color: #0e7a3c;
    --important-bg-color: #faccd5;
    --important-primary-color: #d22645;
    --important-text-color: #b71834;
    --workshops-bg-color: #cce4f3;
    --workshops-primary-color: #0973b2;
    --workshops-text-color: #0f78b7;
    --workshops-difficulty-text-color: #0668a1;
    --workshops-difficulty-bg-color: #99cae7;
    --food-bg-color: #fef7ce;
    --food-primary-color: #e8b71a;
    --food-text-color: #b7730f;
}

.round {
    border-radius: 500px;
}

.events-main {
    border: solid 2px var(--events-primary-color);
    background-color: var(--events-bg-color);
    color: var(--events-text-color);
    font-family: Helvetica;
    font-size: 30px;
}

.events-time {
    color: white;
    font-family: Helvetica;
    font-size: larger;
    background-color: var(--events-primary-color);
    height: 100%;
    width: 10rem;
    text-align: center;
}

.events-icon {
    border-radius: 50%;
    background-color: var(--events-primary-color);
    color: var(--events-bg-color);
    padding: 5px;
}

.events-location {
    color: var(--events-primary-color);
}

.events-indicator {
    color: white;
    background-color: var(--events-primary-color);
}

.important-main {
    border: solid 2px var(--important-primary-color);
    background-color: var(--important-bg-color);
    color: var(--important-text-color);
    font-family: Helvetica;
    font-size: larger;
}

.important-time {
    color: white;
    background-color: var(--important-primary-color);
    height: 100%;
    width: 10rem;
    text-align: center;
}

.important-icon {
    border-radius: 50%;
    background-color: var(--important-primary-color);
    color: var(--important-bg-color);
    padding: 5px;
}

.important-location {
    color: var(--important-primary-color);
}

.important-indicator {
    color: white;
    background-color: var(--important-primary-color);
}

.workshops-main {
    border: solid 2px var(--workshops-primary-color);
    background-color: var(--workshops-bg-color);
    color: var(--workshops-text-color);
}

.workshops-time {
    color: white;
    background-color: var(--workshops-primary-color);
    height: 100%;
    width: 10rem;
    text-align: center;
}

.workshops-icon {
    border-radius: 50%;
    background-color: var(--workshops-primary-color);
    color: var(--workshops-bg-color);
    padding: 5px;
}

.workshops-location {
    color: var(--workshops-primary-color);
}

.workshops-indicator {
    color: white;
    background-color: var(--workshops-primary-color);
}

.workshops-difficulty {
    color: var(--workshops-difficulty-text-color);
    background-color: var(--workshops-difficulty-bg-color);
}

.food-main {
    border: solid 2px var(--food-primary-color);
    background-color: var(--food-bg-color);
    color: var(--food-text-color);
}

.food-time {
    color: white;
    background-color: var(--food-primary-color);
    height: 100%;
    width: 10rem;
    text-align: center;
}

.food-icon {
    border-radius: 50%;
    background-color: var(--food-primary-color);
    color: var(--food-bg-color);
    padding: 5px;
}

.food-location {
    color: var(--food-primary-color);
}

.food-indicator {
    color: white;
    background-color: var(--food-primary-color);
}

.birds {
    position: absolute;
    width: 80px;
    z-index: -1;
    animation: flap 3s ease-in-out infinite alternate;
}

@keyframes flap {
    from { transform: translateY(0%); }
    to { transform: translateY(-40%); }
}

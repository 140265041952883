@keyframes start-loop {
    0% {
        transform: translateX(40%);
    }
    25% {
        transform: translateX(110%);
    }
    100% {
        transform: translateX(110%);
    }
}
@keyframes start-loop-coolguy {
    0% { transform: translateX(40%); }
    100% { transform: translateX(110%); }
}

@keyframes infinite-loop {
    0% { transform: translateX(-20%); }
    30% { transform: translateX(100%); }
    100% { transform: translateX(100%); }
}

@keyframes infinite-loop-just-for-coolguy-because-hes-so-fucking-slow {
    0% { transform: translateX(-20%); }
    100% { transform: translateX(100%); }
}

@keyframes infinite-loop-reverse {
    0% { transform: translateX(110%); }
    50% { transform: translateX(-20%); }
    100% { transform: translateX(-20%); }
}

@keyframes infinite-loop-reverse-coolguy {
    0% { transform: translateX(110%); }
    100% { transform: translateX(-20%); }
}

@keyframes bumping {
    0% { transform: translateY(0%); }
    50% { transform: translateY(1%); }
    100% { transform: translateY(0%); }
}
@keyframes bumping_red {
    0% { transform: translateY(0%); }
    50% { transform: translateY(3%); }
    100% { transform: translateY(0%); }
}

@keyframes swerve-down {
    0% { transform: translateY(0%); }
    50% { transform: translateY(50%); }
    100% { transform: translateY(0%); }
}

@keyframes swerve-up-down {
    0% { transform: translateY(0px); }
    20% { transform: translateY(-30px); }
    50% { transform: translateY(30px); }
    100% { transform: translateY(0px); }
}

.m_green {
    transform: translateX(40%);
    pointer-events: none;
}
.m_green_reverse {
    margin-left: -100px;
}
.animation-green {
    animation: start-loop 10s ease-in-out 1, infinite-loop 10s cubic-bezier(.17,.48,.55,.25) infinite;
    animation-delay: 0s, 10s;
}
.animation-green-reverse {
    opacity: 1;
    animation: infinite-loop-reverse 10s cubic-bezier(.36,.22,.68,.43) infinite;
    animation-delay: 5s;
}

.m_red {
    margin-top: -75px;
    transform: translateX(40%);
    margin-left: -80px;
}
.m_red_reverse {
    margin-top: -40px;
    margin-left: -100px;
}
.animation-red {
    animation: start-loop 10s ease-out 1, infinite-loop 10s cubic-bezier(.34,.16,.21,.51) infinite;
    animation-delay: 0s, 10s;
}
.animation-red-reverse {
    animation: infinite-loop-reverse 10s cubic-bezier(.17,.48,.55,.25) infinite;
    animation-delay: 5s;
}

.m_yellow {
    margin-top: -75px;
    transform: translateX(40%);
    margin-left: -160px;
}
.m_yellow_reverse {
    margin-top: -40px;
    margin-left: -100px;
}
.animation-yellow {
    animation: start-loop 10s ease-in 1, infinite-loop 9s linear infinite;
    animation-delay: 1s, 11s;
}
.animation-yellow-reverse {
    animation: infinite-loop-reverse 9.5s linear infinite;
    animation-delay: 6s;
}

.m_blue {
    margin-top: -75px;
    transform: translateX(40%);
    margin-left: -240px;
}
.m_blue_reverse {
    margin-top: -40px;
    margin-left: -100px;
}
.animation-blue {
    animation: start-loop-coolguy 15s linear 1, infinite-loop-just-for-coolguy-because-hes-so-fucking-slow 25s linear infinite;
    animation-delay: 0s, 15s;
}
.animation-blue-reverse {
    animation: infinite-loop-reverse-coolguy 30s linear infinite;
    animation-delay: 15s;
}


.m_bumping {
    animation: bumping 300ms ease-out infinite;
}
.m_bumping_red {
    animation: bumping 100ms ease-out infinite;
}
.road_curve {
    pointer-events: none;
}



.m_about {
    animation: marquee_red 6s ease-in-out infinite;
}

.m_about_curve {
    animation: swerve-up-down 4s linear infinite
}

.start_flag {
    transition: transform 300ms ease, opacity 300ms ease;
    transform-origin: bottom left;
}
.start_flag:hover {
    transform: scale(1.05);
    opacity: .95;
}
.start_flag.clicked {
    transform: rotate(90deg);
}

.start_text {
    position: absolute;
    color: white;
    font-size: 25px;
    font-family: Helvetica;
    top: 105px;
    left: 30px;

}
.hidden {
    opacity: 0;
    transition: opacity .5s ease-out;
}
@keyframes hide {
    0% { opacity: 1; }
    100% { opacity: 0; }
}




.green_box {
    background-color: #009e44;
}

/*.body_text {*/
/*    font-family: Helvetica;*/
/*    font-size: 18px;*/
/*    color: #FFFFFF;*/
/*    margin: 15px 5px 15px 5px;*/
/*}*/
